import {createContext, useContext, useEffect, useState} from 'react';
import { useSentry } from "./SentryContext";
import { useLocation, useNavigate } from 'react-router-dom';
import{
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    signInWithPhoneNumber
} from "firebase/auth"
import {auth,db} from '../firebase'

import {getDocs, doc, getDoc, query, setDoc, collection, updateDoc, onSnapshot} from 'firebase/firestore'


const UserContext = createContext();

export const AuthContextProvider = ({children})=> {
    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = location;
    const { Sentry } = useSentry();
    const [user, setUser] = useState(null);
    const [gearedUser, setGearedUser] = useState(null);
    const [orgID, setOrgID] = useState('');
    const [truckTypes, setTruckTypes] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [organizationNames, setOrganizationNames] = useState(null);
    const [trucks, setTrucks] = useState([]);
    const [trailers, setTrailers] = useState([]);
    const [loading, setLoading] = useState(true);
  
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [gearedVersion, setGearedVersion] = useState('2.683');
    const [refreshCount, setRefreshCount] = useState(0); 
    const signInWithPhone = async(phoneNumber,appVerifier) =>{
        console.log('wtf this phoneNumber= ' + phoneNumber)
        await Sentry.captureMessage("Trying to sign in with phone number = " + phoneNumber);
        if(phoneNumber.length!==12){
            alert('Phone Number is incorrect length. Current entered phonenumber = ' + phoneNumber)
           return 0;
        }
        else{
            Sentry.captureMessage("Trying to sign in with phone number = " + phoneNumber);
            console.log('appVerifier = ', appVerifier)
            return signInWithPhoneNumber(auth,phoneNumber,appVerifier).then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
            
            
            return confirmationResult;
            
            }).catch((error) => {
                if(error.message==='Firebase: TOO_LONG (auth/invalid-phone-number).')alert('Phone Number TOO LONG, please enter a valid phone number' + phoneNumber)
                if(error.message==='Firebase: TOO_SHORT (auth/invalid-phone-number).')alert('Phone Number TOO SHORT, please enter a valid phone number'+ phoneNumber)
                if(error.message==='Firebase: Invalid format. (auth/invalid-phone-number).')alert('Invalid phone number, please enter a valid phone number'+ phoneNumber)
            console.log('error = ', error); 
                return 0;
            });
        }

    } 
   
    const makeSelectItem = (gearedItem, id)=>{
        gearedItem.text= gearedItem.Name;
        gearedItem.value=id;
 
        gearedItem.ID=id;
        return gearedItem;
    }
    const getGearedTrailers = async(selectedOrgName) =>{
        let gearedTrailers = [];
        gearedTrailers.push(makeSelectItem({Name:'No Trailer'},'')); 
        const q = query(collection(db, "Organizations/"+selectedOrgName+"/Trailers"),);
        const querySnapshot = await getDocs(q);
  
        querySnapshot.forEach((doc) => {
            gearedTrailers.push(makeSelectItem(doc.data(),doc.id)); 
        }); 
        return  Promise.resolve(gearedTrailers);
    }

    const getGearedTrucks = async(selectedOrgName) =>{
        let gearedTrucks = [];
        gearedTrucks.push(makeSelectItem({Name:'No Truck'},'')); 
        const q = query(collection(db, "Organizations/"+ selectedOrgName+"/Trucks"),);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            gearedTrucks.push(makeSelectItem(doc.data(),doc.id)); 
        }); 
        return  Promise.resolve(gearedTrucks);
    }
    const getGearedTruckTypes = async(selectedOrgName) =>{
        let gearedTruckTypes = [];
        gearedTruckTypes.push(makeSelectItem({Name:'No Truck Type'},'')); 
        const q = query(collection(db, "Organizations/"+ selectedOrgName+"/TruckTypes"),);
        const querySnapshot = await getDocs(q);
  
        querySnapshot.forEach((doc) => {
            let trucktype= doc.data();
          
            gearedTruckTypes.push(trucktype ); 
        }); 
        return  Promise.resolve(gearedTruckTypes);
    }
    const getGearedMaterials = async(selectedOrgName) =>{
        let gearedMaterials = [];
        console.log('getting materials for ' + selectedOrgName);
        gearedMaterials.push(makeSelectItem({Name:'No Material'},'')); 
        const q = query(collection(db, "Organizations/"+selectedOrgName+"/Materials"),);
        const querySnapshot = await getDocs(q);
  
        querySnapshot.forEach((doc) => {
            gearedMaterials.push(makeSelectItem(doc.data(),doc.id)); 
        }); 
        return Promise.resolve(gearedMaterials);
    }

    const getGearedOrgNames = async() =>{
        let gearedOrgNames = [];
      console.log('theres no docref coming?');
        const q = query(collection(db, "OrganizationNames"));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            console.log('doc.data() = ', doc.data());
            gearedOrgNames.push(doc.data().ID); 
        }); 
        return Promise.resolve(gearedOrgNames);
    }
    const loginWithConfirmationCode = (confirmationResult,code,phoneNumber) =>{
         
      confirmationResult.confirm(code).then((result) => {
        // User signed in successfully.
        const user = result.user;
        navigate('/home');
        // ...
      }).catch((error) => {
      //  navigate('/');
        alert('Incorrect confirmation code')
      //  setShowConfirmation(true);
        // User couldn't sign in (bad verification code?)
        // ...
      });
    } 
 
    const signIn = (email,password) =>{
        return signInWithEmailAndPassword(auth,email,password);
    } 
    const logout = async()=>{
        if (window.confirm("You are about to LOGOUT of Geared. Are you sure you want to continue?")) {
            Sentry.captureMessage("This user is logging out = " +gearedUser.Name);

            setUser(null);
            setShowConfirmation(false);
            await signOut(auth);
            return navigate('/');
        }else return 0;
       
    }
    const createUser = (email,password)=>{
        return createUserWithEmailAndPassword(auth,email,password);
    }
    const getGearedUser = ()=>{
        return gearedUser;
    }
    const getVersion = async()=>{
        var firstTime=true; 
        console.log('we are gettign versions of geard!!!!! refresh count = ' + refreshCount);
   
        const docRef = doc(db, "TestVersions", "Version");

        
          
      
         onSnapshot(docRef, async(docSnap) => {
          console.log("Current data: ", docSnap.exists());
          const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
  
          if (docSnap.exists() && source ==="Server") {
            docSnap.data().ID=docSnap.id;
    

           
              console.log('we got version, our version = ' +gearedVersion + ' and the database version = ' + docSnap.data().Version);
             if(gearedVersion!==docSnap.data().Version){
                
                  setRefreshCount(refreshCount+1)
                  localStorage.setItem('refreshCount', refreshCount);
               
                  if(refreshCount<5){
                  //  toastr.info('New Version of Geared Loading: ',docSnap.data().Version );
                  setTimeout(function(){window.location.reload()},3000)
                  }//else toastr.warning('Could not load Geared version: ',docSnap.data().Version );
    
             
              
             }else localStorage.setItem('refreshCount', 0);
            if(firstTime)firstTime=false;
            
            }
        });
            console.log('I got Versiosn= ');
       
    }

    const updateGearedUser =async (tempGearedUser)=>{
        console.log('tempGearedUser = ', tempGearedUser)
        console.log('gearedUSer .id = ', gearedUser)
        if(gearedUser.ID){
            if(gearedUser.Name!==tempGearedUser.Name ||  gearedUser.selectedOrgName !== tempGearedUser.selectedOrgName){
                gearedUser.Name=tempGearedUser.Name;
                gearedUser.selectedOrgName = tempGearedUser.selectedOrgName;
                localStorage.setItem('currentOrg', tempGearedUser.selectedOrgName);
                getDropDowns(tempGearedUser.selectedOrgName);
                setGearedUser(gearedUser);
                let docRef = doc(db, "users", gearedUser.ID);
                await updateDoc(docRef, {Name:tempGearedUser.Name,selectedOrgName:tempGearedUser.selectedOrgName});
            }
        }
     
    }
    const getDropDowns = (selectedOrgName)=>{
        getGearedTruckTypes(selectedOrgName).then((trucktypes) => {setTruckTypes(trucktypes);  });
        getGearedMaterials(selectedOrgName).then((materials) => {setMaterials(materials);   });
        getGearedOrgNames(selectedOrgName).then((organizationNames) => {setOrganizationNames(organizationNames);   });
        getGearedTrucks(selectedOrgName).then((trucks) => {setTrucks(trucks);   });
        getGearedTrailers(selectedOrgName).then((trailers) => {setTrailers(trailers);   });
    }
    useEffect(()=>{
        //first thing to run 
        getVersion();
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            console.log('current user = ', currentUser);
           
            if(currentUser){
                setUser(currentUser);
                const getUser = async () => {
                    const docRef = doc(db, "users", currentUser.uid);
                    const docSnap = await getDoc(docRef);     
                    if (docSnap.exists()) {
                        console.log("Document data:", docSnap.data());
                    
                        let tempUser = docSnap.data();
                        Sentry.configureScope((scope) => { scope.setUser({"username":  docSnap.data().Name});  });
                        window.heap.identify( docSnap.data().PhoneNumber);
                        tempUser.OptIn=true;
                        if(tempUser.selectedOrgName) {
                            localStorage.setItem('currentOrg', tempUser.selectedOrgName);
                            setOrgID(tempUser.selectedOrgName)
                         } else {
                            localStorage.setItem('currentOrg', "John's Trucking");
                            setOrgID( "John's Trucking")
                         }
                         Sentry.captureMessage("We have set up a user and the version is 6 and sentry is working again for user =   " + docSnap.data().Name  +" and their local storage = " +  localStorage.currentOrg);
              
                         console.log()
                        if(tempUser.update)tempUser.update=false; else tempUser.update=true;
                        setGearedUser(prevUser => ({ ...prevUser, ...tempUser }));
                         console.log("geared USER IS SET!", docSnap.data());
                       
                        setDoc(docRef,  tempUser);
                        getDropDowns(docSnap.data().selectedOrgName);
                      
                 
                       // Sentry.captureMessage("We have set up a user and sentry is working again for user =   " + docSnap.data().Name );


                    } else {
                     let FBUser = { ID:currentUser.uid, Name:currentUser.phoneNumber.substring(2,12),PhoneNumber:currentUser.phoneNumber.substring(2,12),  Type: "Driver" };
                     doc(db, "users", currentUser.uid);
                     await setDoc(docRef, FBUser );     
                     setGearedUser(FBUser)
                    // docSnap.data() will be undefined in this case
                    console.log("No such document!");
                    }
                };
                getUser();
             
                console.log('is localstorage a thing ? ' , localStorage);
              
         
                setLoading(false);
               
            }else{
                if(pathname.includes("freightbill") || pathname.includes("dispatch")){
                    let splitString = pathname.split("freightbill/");
                    console.log('index of freightbill' + splitString )
                    navigate('/')
                }else navigate('/')
              
            }  
        });
 
        return ()=>{
            setLoading(false);
            unsubscribe();
        }
    },[])


      
    return(
        <UserContext.Provider value={{ createUser, user, logout, signIn, signInWithPhone, updateGearedUser,setGearedUser,showConfirmation, setShowConfirmation, organizationNames,getGearedUser, gearedUser, loginWithConfirmationCode, truckTypes, materials, loading, trucks, trailers}}>
        {children}
        </UserContext.Provider>
    );
}


export const UserAuth =() =>{
    return useContext(UserContext);
  
}

